<script setup>
const props = defineProps({
    to: {
        type: [String, Object],
        required: false,
        default: '',
    },
    external: {
        type: Boolean,
        default: false,
    },
    target: {
        type: String,
        default: '',
    },
})

const makeFixedUri = computed(() => {
    if (props.external || props.target === '_blank') {
        return props.to
    }
    const val = fixedUri(props.to)
    return val
})
</script>

<template>
    <NuxtLink
        :to="makeFixedUri"
    >
        <slot />
    </NuxtLink>
</template>
